import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledLoaderSection = styled.div`
.skill-main {
  width: 500px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .skill-wrrap {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .skill-bar {
      height: 20px;
      background-color: #00283a;
      border-radius: 3px;
    }

    .skill-per {
      font-size: 100px;
      height: 20px;
      background: var(--green);
      border-radius: 2px;
      width: 0;
      transition: 1s linear;
      position: relative;
      &:before {
        content: attr(per);
        position: absolute;
        padding: 4px 6px;
        background-color: var(--green));
        color: #fff;
        font-size: 11px;
        border-radius: 2px;
        top: -35px;
        right: 0;
        transform: translateX(50%);
      }

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--green));
        top: -20px;
        right: 0;
        transform: translateX(50%) rotate(45deg);
        border-radius: 2px;
      }
    }
  }
}
`;

const IconLoader = () => {
  const skillPersRef = useRef([]);

  useEffect(() => {
    skillPersRef.current.forEach(skillPer => {
      const per = parseFloat(skillPer.getAttribute('per'));
      skillPer.style.width = `${per}%`;

      let animatedValue = 0;
      let startTime = null;

      function animate(timestamp) {
        if (!startTime) {
          startTime = timestamp;
        }
        const progress = timestamp - startTime;
        const stepPercentage = progress / 1000; // Dividing by duration in milliseconds (1000ms = 1s)

        if (stepPercentage < 1) {
          animatedValue = per * stepPercentage;
          skillPer.setAttribute('per', `${Math.floor(animatedValue)}%`);
          requestAnimationFrame(animate);
        } else {
          animatedValue = per;
          skillPer.setAttribute('per', `${Math.floor(animatedValue)}%`);
        }
      }

      requestAnimationFrame(animate);
    });
  }, []);

  return (
    <StyledLoaderSection>
      <div className="skill-main">
        <div className="skill-wrrap">
          <div className="skill-bar">
            <div className="skill-per" per="100" ref={el => skillPersRef.current.push(el)}></div>
          </div>
        </div>
      </div>
    </StyledLoaderSection>
  );
};

export default IconLoader;
